var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "form-search",
      attrs: { id: "js-header-search", action: "/search" },
      on: {
        click: function ($event) {
          return _vm.onSearchClickOutside($event)
        },
        submit: function ($event) {
          $event.preventDefault()
          return _vm.searchHandler($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "input-group",
          class: { focused: _setup.$store.state.ui.isSearchOpened },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            ref: "inputSearch",
            staticClass: "form-control form-control-lg",
            attrs: {
              type: "text",
              name: "searchItem",
              placeholder: _vm.$t("layouts_main_0023"),
              "aria-label": "Recipient's username",
              "aria-describedby": "button-addon2",
              autocomplete: "off",
            },
            domProps: { value: _vm.searchTerm },
            on: {
              keydown: _vm.onKeyDown,
              focus: _vm.onInputFocus,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("base-btn", {
            staticClass: "btn-close",
            class: {
              "d-block": _setup.$store.state.ui.isSearchOpened,
              "btn-close-white": _vm.searchTerm.length,
            },
            attrs: { type: "button", variant: false },
            nativeOn: {
              click: function ($event) {
                return _vm.onClearClick()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "base-btn",
            {
              attrs: {
                type: "submit",
                variant: "primary",
                "aria-label": _vm.$t("layouts_main_0023"),
              },
            },
            [
              _c(
                "base-icon",
                [_c("BaseSvg", { attrs: { name: "search" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSuggestionsLoading
        ? _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar progress-bar-indeterminate",
              attrs: { role: "progressbar" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isListOpen
        ? _c(_setup.TheHeaderSearchResults, {
            attrs: {
              results: _vm.results,
              "search-term": _vm.searchTerm,
              "product-history": _vm.dataStoreHistoryPreview,
              products: _vm.resultsCached.products,
            },
            on: {
              "click-results": function ($event) {
                return _vm.clickResult($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }