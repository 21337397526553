<template>
    <form
        id="js-header-search"
        ref="form"
        action="/search"
        class="form-search"
        @click="onSearchClickOutside($event)"
        @submit.prevent="searchHandler($event)"
    >
        <div
            class="input-group"
            :class="{ focused: $store.state.ui.isSearchOpened }"
        >
            <input
                ref="inputSearch"
                v-model="searchTerm"
                type="text"
                name="searchItem"
                :placeholder="$t('layouts_main_0023')"
                class="form-control form-control-lg"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                autocomplete="off"
                @keydown="onKeyDown"
                @focus="onInputFocus"
            >
            <base-btn
                type="button"
                class="btn-close"
                :class="{
                    'd-block': $store.state.ui.isSearchOpened,
                    'btn-close-white': searchTerm.length,
                }"
                :variant="false"
                @click.native="onClearClick()"
            />
            <base-btn
                type="submit"
                variant="primary"
                :aria-label="$t('layouts_main_0023')"
            >
                <base-icon>
                    <BaseSvg name="search" />
                </base-icon>
            </base-btn>
        </div>
        <div
            v-if="isSuggestionsLoading"
            class="progress"
        >
            <div
                class="progress-bar progress-bar-indeterminate"
                role="progressbar"
            />
        </div>
        <the-header-search-results
            v-if="isListOpen"
            :results="results"
            :search-term="searchTerm"
            :product-history="dataStoreHistoryPreview"
            :products="resultsCached.products"
            @click-results="clickResult($event)"
        />
    </form>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';
import { useHead } from '@unhead/vue';
import search from '../store/modules/search';
import { registerModules } from '../utils/store';
import { htmlSanitizer } from '../utils/filters';
import { historyMixin } from '../utils/mixins';
import { useCurrentInstance } from '../composables/useCurrentInstance';

const TheHeaderSearchResults = defineAsyncComponent(() => import('./TheHeaderSearchResults.vue'));

const { $store } = useCurrentInstance();
useHead({
    bodyAttrs: {
        class: {
            'search-open': () => $store.state.ui.isSearchOpened,
        },
    },
});
</script>

<script>
export default {
    name: 'TheHeaderSearch',

    mixins: [
        historyMixin,
    ],

    data: () => ({
        searchTerm: '',
        activeResultIndex: -1,
        isListOpen: false,
        isSuggestionsLoading: false,
        debounceId: null,
        resultsCached: {
            products: {},
        },
    }),

    computed: {
        needProductHistory() {
            return this.$store.state.ui.switch.web.header.formSearchProductHistory;
        },
        searchState() {
            return this.$store.state.header.search;
        },
        results() {
            const { results } = this.searchState;

            // TODO remove this side effect
            if (results.products) this.resultsCached.products = results.products;

            return results;
        },
        isRecommended() {
            return Object.keys(this.resultsCached.products).length === 0;
        },
    },

    watch: {
        '$route.query.searchItem': function updateSearchTerm(newValue) {
            this.searchTerm = newValue || '';
        },
        '$store.state.ui.isSearchOpened': function isSearchOpened(opened) {
            if (opened) window.timerNotificationNewsletter?.pause();
            else window.timerNotificationNewsletter?.resume();
        },
        $route() {
            if (!this.$ssrContext) {
                this.searchFormUnfocus();
            }
        },
    },

    created() {
        this.registerModule();
    },

    mounted() {
        this.searchTerm = this.$route.query.searchItem || '';
    },

    methods: {
        registerModule() {
            const modules = [
                { name: ['header', 'search'], imported: search, preserveState: this.searchState },
            ];

            registerModules(this.$store, modules);
        },

        fetchHistoryProducts({ store = this.$store } = {}) {
            if (!this.needProductHistory) return;

            this.registerModuleHistory({ store });

            this.historyFetch();
        },
        searchHandler() {
            if (this.isListOpen && this.activeResultIndex >= 0) {
                this.selectResult(this.activeResultIndex);
                this.$store.dispatch('datalayer/clickedSearchSuggestion');
            }
            if (this.searchTerm.length > 2 && this.searchTerm !== this.$route.query.searchItem) {
                this.submitForm();
            }
        },

        selectResult(resultIndex) {
            this.searchTerm = this.results.queries[resultIndex].name;
        },

        submitForm() {
            this.closeResultsList();
            this.searchFormUnfocus();

            this.$router.push(this.routeToSearch({
                path: this.$refs.form.getAttribute('action'),
                term: htmlSanitizer(this.searchTerm),
            }));
        },

        clickResult(index) {
            this.activeResultIndex = index;
            this.searchHandler();
        },

        getIterableLength() {
            if (this.results.categories && !Array.isArray(this.results.categories)) {
                return this.results.categories.items.length + this.results.queries.length - 1;
            }

            return this.results.queries.length - 1;
        },

        onKeyDown(event) {
            if (!this.$store.state.ui.switch.web.header.formSearchSuggests) return;

            switch (event.keyCode) {
            case 40: // down
                if (this.activeResultIndex < this.getIterableLength()) {
                    this.activeResultIndex += 1;
                } else {
                    this.activeResultIndex = 0;
                }
                break;
            case 38: // up
                if (this.activeResultIndex > 0) {
                    this.activeResultIndex -= 1;
                } else {
                    this.activeResultIndex = this.getIterableLength();
                }
                break;
            case 27: // esc
            case 9: // tab
                this.seachFormReset();
                break;
            default:
                clearTimeout(this.debounceId);
                this.debounceId = setTimeout(() => {
                    // only one call will be made to this getSuggestions, after the last key stroke
                    this.getSuggestions(this.searchTerm);
                }, 250);
            }
        },

        onInputFocus() {
            if (!this.$store.state.ui.switch.web.header.formSearchSuggests) return;

            this.$store.dispatch('ui/toggleSearch', true);
            this.fetchHistoryProducts();
            this.getSuggestions(this.searchTerm);
        },

        getSuggestions(term) {
            if (term.length === 0 || term.length > 2) {
                this.isSuggestionsLoading = true;
                const sanitizedTerm = htmlSanitizer(term);
                const payload = {
                    sanitizedTerm,
                    isRecommended: this.isRecommended,
                };
                this.$store.dispatch('header/search/fetchData', payload).then(() => {
                    this.openResultsList();
                    this.isSuggestionsLoading = false;
                });
            }
        },

        routeToSearch: ({ path, term }) => ({
            path,
            query: {
                searchItem: term,
            },
        }),

        openResultsList() {
            this.isListOpen = true;
            this.activeResultIndex = -1;
        },

        onSearchClickOutside(event) {
            if (event.target.tagName !== 'FORM') return;

            if (!this.$route.query.searchItem) {
                this.inputSearchClear();
            }
            this.searchFormUnfocus();
        },

        closeResultsList() {
            this.isListOpen = false;
            this.activeResultIndex = -1;
        },

        onClearClick() {
            if (this.searchTerm.length) {
                this.inputSearchClear();
                this.$refs.inputSearch.focus();
            } else {
                this.searchFormUnfocus();
            }
        },

        seachFormReset() {
            this.inputSearchClear();
            this.searchFormUnfocus();
        },

        inputSearchClear() {
            this.searchTerm = '';
            this.activeResultIndex = -1;
        },

        searchFormUnfocus() {
            this.$refs.inputSearch.blur();
            this.$store.dispatch('ui/toggleSearch', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./../scss/required";
@import "./../scss/05-components/progress";

.input-group + .progress {
    height: 2px;
    margin-top: -2px;

    .progress-bar {
        z-index: 3;
        height: 100%;
    }
}
</style>

<style lang="scss">
@import "./../scss/required";

$search-max-width:      700px;

.form-search {
    &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        content: "";
        background-color: $white;
    }

    .form-control {
        height: $input-height-lg;
        padding: $input-padding-y-lg $input-padding-x-lg;
        @include font-size($input-font-size-lg);
        background-color: transparent;
        border-right: 0;
        transition: none;

        &:focus {
            border-color: transparent;
            box-shadow: none;
        }
    }

    .input-group {
        background-color: $white;
        border-radius: $border-radius;

        &.focused {
            box-shadow: $input-focus-box-shadow;

            .form-control {
                border-color: transparent;
            }
        }

        .btn-close {
            display: none;
            height: auto;
            padding: 0 $spacer;
            border-radius: 0;

            &:focus {
                box-shadow: none;
            }

            &.btn-close-white {
                position: relative;
                background-color: $gray-400;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        max-width: $search-max-width;

        &::before {
            background-color: $black-20;
        }

        .form-control {
            height: $input-height;
            padding: $input-padding-y $input-padding-x;
            @include font-size($input-font-size);
            line-height: $input-line-height;
        }

        .progress {
            display: none;
        }
    }
}

.search-open {
    .form-search {
        &::before {
            display: block;
        }

        .progress {
            display: flex;
        }
    }
}
</style>
