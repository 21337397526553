import { searchTermService } from '../../services';

const resultsToKeep = 50;

export default {
    namespaced: true,

    state: () => ({
        results: {},
        cache: [],
    }),

    getters: {
        getCachedResultsByTerm(state) {
            return (term) => {
                // trimming the spaces so we don't double cache the same term
                const trimmedTerm = term.trim();
                const resultObject = state.cache.find((result) => result[trimmedTerm]);
                return resultObject ? resultObject[trimmedTerm] : undefined;
            };
        },
    },

    mutations: {
        SET_DATA(state, { results, term, getters }) {
            this.$set(state, 'results', results);

            // checking if the word is not already cached
            if (!getters.getCachedResultsByTerm(term)) {
                state.cache.push({ [term]: results });
            }

            // if we have to many results cached, delete the first one
            if (state.cache.length > resultsToKeep) {
                state.cache.shift();
            }
        },
        SET_RESULTS(state, results) {
            this.$set(state, 'results', results);
        },
    },

    actions: {
        fetchData({ commit, getters, rootState }, { sanitizedTerm, isRecommended }) {
            const cachedResults = getters.getCachedResultsByTerm(sanitizedTerm);

            if (cachedResults) {
                commit('SET_RESULTS', cachedResults);
                return new Promise((resolve) => resolve(cachedResults));
            }

            const accessToken = rootState.storage.api_v2_token;
            return searchTermService({
                $http: this.$http,
                params: {
                    ...(accessToken && { accessToken }),
                    source: rootState.params.source,
                    lang: rootState.params.country.lang,
                    query: sanitizedTerm,
                    recommended: isRecommended,
                },
            }).then((response) => {
                commit('SET_DATA', { results: response, term: sanitizedTerm, getters });
            }).catch((error) => {
                console.error('searchTermService: ', error);
            });
        },
    },
};
